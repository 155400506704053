import { GridRowId } from "@mui/x-data-grid-premium";
import { DefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { LayoutContext, fetchGet, fetchPost, fetchPut, fetchDelete, Error } from "wcz-layout";
import { baseUrl } from "../utils/BaseUrl";
import CycleCount, { initCycleCount } from "../models/CycleCount";
import CycleCountItem from "../models/CycleCountItem";

const route: string = "v1/CycleCount";

export const useGetCycleCounts = (options?: Omit<DefinedInitialDataOptions<CycleCount[], Error, CycleCount[]>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<CycleCount[], Error, CycleCount[]>({
        ...options,
        queryKey: [route],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetCycleCount = (id: GridRowId, options?: Omit<DefinedInitialDataOptions<CycleCount, Error, CycleCount>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<CycleCount, Error, CycleCount>({
        ...options,
        queryKey: [route, id],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/${id}`, signal),
        initialData: initCycleCount,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useCreateCycleCount = (options?: Omit<UseMutationOptions<CycleCount, Error, CycleCount>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<CycleCount, Error, CycleCount>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: CycleCount[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

interface CreateCycleCountItemModel {
    cycleCountId: string;
    locationName: string;
}

export const useCreateCycleCountItem = (options?: Omit<UseMutationOptions<CreateCycleCountItemModel, Error, CreateCycleCountItemModel>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<CreateCycleCountItemModel, Error, CreateCycleCountItemModel>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}/${model.cycleCountId}/location/${model.locationName}`, model),
        onError: (error, _newTodo) => {
            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useUpdateCycleCount = (options?: Omit<UseMutationOptions<CycleCount, Error, CycleCount>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<CycleCount, Error, CycleCount>({
        ...options,
        mutationFn: model => fetchPut(`${baseUrl}/${route}/${model.id}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: CycleCount[]) => old.map(prev => prev.id === model.id ? model : prev));

            const previousData = queryClient.getQueryData([route, model.id]);
            if (previousData)
                queryClient.setQueryData([route, model.id], model);

            return { previousDataList, previousData };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);
            queryClient.setQueryData([route, context.previousData.CycleCountId], context.previousData);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useUpdateCycleCountItem = (cycleCountId: string, options?: Omit<UseMutationOptions<CycleCountItem, Error, CycleCountItem>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<CycleCountItem, Error, CycleCountItem>({
        ...options,
        mutationFn: model => fetchPut(`${baseUrl}/${route}/${cycleCountId}/item`, model),
        onError: (error, _newTodo) => {
            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useDeleteCycleCount = (options?: Omit<UseMutationOptions<GridRowId, Error, GridRowId>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<GridRowId, Error, GridRowId>({
        ...options,
        mutationFn: id => fetchDelete(`${baseUrl}/${route}/${id}`),
        onMutate: async id => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: CycleCount[]) => old.filter(prev => prev.id !== id));

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};

export const useDeleteCycleCountItem = (options?: Omit<UseMutationOptions<GridRowId, Error, GridRowId>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<GridRowId, Error, GridRowId>({
        ...options,
        mutationFn: id => fetchDelete(`${baseUrl}/${route}/item/${id}`),
        onError: (error, _newTodo) => {
            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [route], exact: false }),
    });
};