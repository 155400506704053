import { DataGridPremium, GridActionsCellItem, GridColDef, GridInitialState, GridRowParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { Fragment, useContext, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridToolbar, hasRole, LayoutContext, TableContainer, useLocalStorageState } from "wcz-layout";
import Location from "../../models/Location";
import { useGetLocations } from "../../queries/LocationQueries";
import { Add, LocationOn } from "@mui/icons-material";
import { Button } from "@mui/material";
import AuthPolicy from "../../utils/AuthPolicy";
import { GridToolbarProps } from "wcz-layout/dist/src/components/dataGrid/GridToolbar";

export const LocationsPage: React.FC = () => {
    const { t } = useContext(LayoutContext);
    const [gridState, setGridState] = useLocalStorageState<GridInitialState>("locationsGridState");
    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const { data, isFetching } = useGetLocations();

    const handleOnRowDoubleClick = (params: GridRowParams) => navigate(`/locations/${params.id}`);

    const columns: GridColDef<Location>[] = [
        {
            field: "actions", type: "actions", width: 50, getActions: ({ id }) => [
                <GridActionsCellItem key="material" label={t("Material")} icon={<LocationOn />} onClick={() => navigate(`/locations/${id}`)} />,
            ],
        },
        { field: "isWarehouse", headerName: t("IsWarehouse"), width: 150, type: "boolean", },
        { field: "name", headerName: t("Name"), width: 200, },
        { field: "building", headerName: t("Building"), },
        { field: "equipmentRoom", headerName: t("EquipmentRoom"), width: 150, },
        { field: "rack", headerName: t("Rack"), },
        { field: "shelf", headerName: t("Shelf"), },
        { field: "box", headerName: t("Box"), },
        { field: "remark", headerName: t("Remark"), width: 350, },
    ];

    const saveSnapshot = () => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            setGridState(currentState);
        }
    };

    useLayoutEffect(() => {
        window.addEventListener("beforeunload", saveSnapshot);

        return () => {
            saveSnapshot();
            window.removeEventListener("beforeunload", saveSnapshot);
        };
    }, [saveSnapshot]);

    return (
        <TableContainer>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        actions: [
                            <Fragment key="new">
                                {hasRole(AuthPolicy.Admin) && <Button size="small" startIcon={<Add />} onClick={() => navigate("/locations/create")}>{t("New")}</Button>}
                            </Fragment>,
                        ]
                    } as GridToolbarProps
                }}
                onRowDoubleClick={handleOnRowDoubleClick}
                loading={isFetching}
                ignoreDiacritics
                apiRef={apiRef}
                initialState={gridState}
            />
        </TableContainer >
    );
};