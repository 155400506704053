import { Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Container, Grid2, TextField } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { ZodValidator, zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, useContext } from "react";
import { LayoutContext } from "wcz-layout";
import { z } from "zod";
import Employee, { initEmployee } from "../../models/Employee";
import { useCreateEmployee } from "../../queries/EmployeeQueries";
import { useGetPeoplesoftDepartments } from "../../queries/PeoplesoftQueries";
import { useNavigate } from "react-router-dom";

export const CreateEmployeePage: React.FC = () => {
    const { t, snackbar } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data: peoplesoftDepartments } = useGetPeoplesoftDepartments();

    const { Field, Subscribe, handleSubmit } = useForm<Employee, ZodValidator>({
        defaultValues: initEmployee,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => mutate({
            ...value,
            id: value.id.toUpperCase(),
            shouldBeInactivated: false,
        }),
    });

    const { mutate } = useCreateEmployee({
        onSuccess: data => {
            snackbar({ title: `${data.firstName} ${data.lastName} ${t("Created").toLowerCase()}` });
            navigate(`/employees/${data.id}`);
        }
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    return (
        <Container sx={{ my: 2 }}>
            <Card variant="outlined">
                <CardHeader title={t("CreateEmployee")} />
                <form onSubmit={handleOnSubmit}>
                    <CardContent>
                        <Grid2 container spacing={2}>
                            <Grid2 size={12}>
                                <Field name="firstName" validators={{ onChange: z.string().min(1) }}>
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                            label={t("FirstName")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12}>
                                <Field name="lastName" validators={{ onChange: z.string().min(1) }}>
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                            label={t("LastName")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12}>
                                <Field name="id" validators={{ onChange: z.string().min(1) }}>
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                            label="ID" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12}>
                                <Field name="department" validators={{ onChange: z.string().min(1) }}>
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <Autocomplete
                                            value={state.value}
                                            options={peoplesoftDepartments}
                                            autoHighlight
                                            onChange={(_, value) => handleChange(value!)}
                                            renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Department")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />}
                                        />
                                    }
                                </Field>
                            </Grid2>
                            <Grid2 size={12}>
                                <Field name="company" validators={{ onChange: z.string().min(1) }}>
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <Autocomplete
                                            value={state.value}
                                            options={["WCZ", "WSCZ"]}
                                            autoHighlight
                                            onChange={(_, value) => handleChange(value!)}
                                            renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Company")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />}
                                        />
                                    }
                                </Field>
                            </Grid2>
                        </Grid2>
                    </CardContent>

                    <CardActions sx={{ justifyContent: "end" }}>
                        <Subscribe selector={(state) => [state.canSubmit]}>
                            {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                        </Subscribe>
                    </CardActions>
                </form>
            </Card>
        </Container>
    );
};