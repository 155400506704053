import { Add, CheckBox, CheckBoxOutlineBlank, Edit, Visibility } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridInitialState, GridRowModel, GridRowParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { Fragment, useContext, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridToolbar, hasRole, LayoutContext, useLocalStorageState } from "wcz-layout";
import { GridToolbarProps } from "wcz-layout/dist/src/components/dataGrid/GridToolbar";
import { MaterialState } from "../../models/enums/MaterialState";
import Location from "../../models/Location";
import Material from "../../models/Material";
import { MaterialIcon } from "../../pages/materials/MaterialIcon";
import { useUpdateMaterial } from "../../queries/MaterialQueries";
import AuthPolicy from "../../utils/AuthPolicy";
import { keeperGetter, warrantyDateGetter } from "../../utils/Helpers";
import { MaterialBatchReceiveButton } from "./MaterialBatchReceiveButton";
import { MaterialBatchRelocationButton } from "./MaterialBatchRelocationButton";

interface MaterialDataGridProps {
    data: Material[];
    isFetching: boolean;
}

export const MaterialDataGrid: React.FC<MaterialDataGridProps> = ({ data, isFetching }) => {
    const { t, snackbar } = useContext(LayoutContext);
    const navigate = useNavigate();
    const [gridState, setGridState] = useLocalStorageState<GridInitialState>("materialsGridState");
    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [editMode, setEditMode] = useState<boolean>(false);
    const apiRef = useGridApiRef();

    const handleOnKeeperClick = (employeeId: string | undefined) => () => (employeeId && !editMode) && navigate(`/employees/${employeeId}`);
    const handleOnLocationClick = (locationId: string | undefined) => () => (locationId && !editMode) && navigate(`/locations/${locationId}`);

    const handleOnRowDoubleClick = (params: GridRowParams) => !editMode && navigate(`/materials/${params.id}`);

    const columns: GridColDef<Material>[] = [
        {
            field: "actions", type: "actions", width: 50, getActions: ({ id, row }) => [
                <GridActionsCellItem key="material" label={t("Material")} icon={<MaterialIcon material={row} color="inherit" />} onClick={() => navigate(`/materials/${id}`)} />,
            ],
        },
        { field: "company", headerName: t("Company"), width: 120, cellClassName: editMode ? "not-editable--cell" : undefined, },
        {
            field: "keeper", headerName: t("Keeper"), width: 250, valueGetter: keeperGetter, renderCell: ({ value, row }) =>
                <Typography
                    variant="body2"
                    onClick={handleOnKeeperClick(row.keeper?.id)}
                    sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                >
                    {value}
                </Typography>,
            cellClassName: editMode ? "not-editable--cell" : undefined,
        },
        { field: "type", headerName: t("Type"), width: 150, cellClassName: editMode ? "not-editable--cell" : undefined, },
        { field: "manufacturer", headerName: t("Manufacturer"), width: 150, cellClassName: editMode ? "not-editable--cell" : undefined, },
        { field: "model", headerName: "Model", width: 220, cellClassName: editMode ? "not-editable--cell" : undefined, },
        { field: "hostname", headerName: t("Hostname"), width: 200, editable: hasRole(AuthPolicy.Admin), },
        { field: "serialNumber", headerName: "SN", width: 170, editable: hasRole(AuthPolicy.Admin), },
        { field: "fixAsset", headerName: "Fix Asset", width: 160, cellClassName: editMode ? "not-editable--cell" : undefined, },
        { field: "imei", headerName: "IMEI", width: 170, editable: hasRole(AuthPolicy.Admin), },
        {
            field: "location", headerName: t("Location"), width: 220, valueGetter: (value: Location) => value?.name,
            renderCell: ({ value, row }) =>
                <Typography
                    variant="body2"
                    onClick={handleOnLocationClick(row.location?.id)}
                    sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                >
                    {value}
                </Typography>,
            cellClassName: editMode ? "not-editable--cell" : undefined,
        },
        { field: "department", headerName: t("Department"), width: 120, editable: hasRole(AuthPolicy.Admin), },
        { field: "partNumber", headerName: "PN", width: 170, editable: hasRole(AuthPolicy.Admin), },
        { field: "orderDate", headerName: t("OrderDate"), width: 150, type: "date", valueGetter: value => value && new Date(value), editable: hasRole(AuthPolicy.Admin), },
        { field: "monthsOfWarranty", headerName: t("Warranty"), type: "date", valueGetter: warrantyDateGetter, editable: hasRole(AuthPolicy.Admin), },
        { field: "state", headerName: t("State"), width: 150, type: "singleSelect", valueOptions: Object.values(MaterialState), editable: hasRole(AuthPolicy.Admin), },
        { field: "underRepairTo", headerName: t("UnderRepairTo"), width: 150, type: "date", valueGetter: value => value && new Date(value), editable: hasRole(AuthPolicy.Admin), },
        { field: "price", headerName: t("Price"), width: 120, type: "number", editable: hasRole(AuthPolicy.Admin), },
        { field: "currency", headerName: t("Currency"), width: 120, editable: hasRole(AuthPolicy.Admin), },
        { field: "itId", headerName: "IT ID", width: 150, editable: hasRole(AuthPolicy.Admin), },
        { field: "stockQuantity", headerName: t("StockQuantity"), width: 190, type: "number", editable: hasRole(AuthPolicy.Admin), },
        { field: "safetyStock", headerName: t("SafetyStock"), width: 190, type: "number", editable: hasRole(AuthPolicy.Admin), },
        { field: "remark", headerName: t("Remark"), width: 350, editable: hasRole(AuthPolicy.Admin), },
        { field: "ip", headerName: "IP", width: 150, editable: hasRole(AuthPolicy.Admin), },
        { field: "macLan", headerName: "MAC LAN", width: 250, editable: hasRole(AuthPolicy.Admin), },
        { field: "macWlan", headerName: "MAC WLAN", width: 250, editable: hasRole(AuthPolicy.Admin), }
    ];

    const saveSnapshot = () => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            setGridState(currentState);
        }
    };

    useLayoutEffect(() => {
        window.addEventListener("beforeunload", saveSnapshot);

        return () => {
            saveSnapshot();
            window.removeEventListener("beforeunload", saveSnapshot);
        };
    }, [saveSnapshot]);

    const { mutateAsync } = useUpdateMaterial();

    const processRowUpdate = async (newRow: GridRowModel<Material>) => {
        await mutateAsync(newRow)
            .then(() => snackbar({ title: `${newRow.name} ${t("Updated").toLowerCase()}` }));
        return newRow;
    };

    return (
        <DataGridPremium
            rows={data}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: {
                    hideAddNewRow: true,
                    actions: [
                        <Fragment key="new">
                            {(hasRole(AuthPolicy.Admin) && !window.location.pathname.includes("create")) && <Button size="small" startIcon={<Add />} onClick={() => navigate("/materials/create")}>{t("New")}</Button>}
                            {hasRole(AuthPolicy.Admin) && <Button size="small" startIcon={editMode ? <Edit /> : <Visibility />} onClick={() => setEditMode(!editMode)}>{t(editMode ? "EditMode" : "ViewMode")}</Button>}
                        </Fragment>,
                        <Button key="checkboxSelection" startIcon={checkboxSelection ? <CheckBox /> : <CheckBoxOutlineBlank />} onClick={() => setCheckboxSelection(!checkboxSelection)}>{t("Selection")}</Button>,
                        <MaterialBatchReceiveButton key="batchReceiveButton" rowSelectionModel={rowSelectionModel} data={data.filter(material => material.state === MaterialState.InUse)} />,
                        <MaterialBatchRelocationButton key="batchRelocationButton" rowSelectionModel={rowSelectionModel} data={data} />
                    ]
                } as GridToolbarProps
            }}
            onRowDoubleClick={handleOnRowDoubleClick}
            loading={isFetching}
            ignoreDiacritics
            checkboxSelection={checkboxSelection}
            onRowSelectionModelChange={(newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel)}
            editMode="row"
            processRowUpdate={processRowUpdate}
            disableRowSelectionOnClick
            cellSelection
            apiRef={apiRef}
            initialState={gridState}
            sx={{ "& .not-editable--cell": { opacity: 0.5 } }}
        />
    );
};