import { Add, Person } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridInitialState, GridRowParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { Fragment, useContext, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GridToolbar, hasRole, LayoutContext, TableContainer, useLocalStorageState } from "wcz-layout";
import { GridToolbarProps } from "wcz-layout/dist/src/components/dataGrid/GridToolbar";
import Employee from "../../models/Employee";
import { EmployeeStatus } from "../../models/enums/EmployeeStatus";
import { useGetEmployees } from "../../queries/EmployeeQueries";
import AuthPolicy from "../../utils/AuthPolicy";

export const EmployeesPage: React.FC = () => {
    const { t } = useContext(LayoutContext);
    const [gridState, setGridState] = useLocalStorageState<GridInitialState | undefined>("employeesGridState");
    const navigate = useNavigate();
    const apiRef = useGridApiRef();

    const { data, isFetching } = useGetEmployees();

    const handleOnRowDoubleClick = (params: GridRowParams) => navigate(`/employees/${params.id}`);

    const columns: GridColDef<Employee>[] = [
        {
            field: "actions", type: "actions", width: 50, getActions: ({ id }) => [
                <GridActionsCellItem key="material" label={t("Material")} icon={<Person />} onClick={() => navigate(`/employees/${id}`)} />,
            ],
        },
        { field: "firstName", headerName: t("FirstName"), width: 130, },
        { field: "lastName", headerName: t("LastName"), width: 130, },
        { field: "id", headerName: "ID", width: 150, },
        { field: "department", headerName: t("Department"), width: 150, },
        { field: "company", headerName: t("Company"), width: 150, type: "singleSelect", valueOptions: ["WCZ", "WSCZ"] },
        { field: "status", headerName: "Status", width: 150, type: "singleSelect", valueOptions: Object.keys(EmployeeStatus) },
    ];

    const saveSnapshot = () => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            setGridState(currentState);
        }
    };

    useLayoutEffect(() => {
        window.addEventListener("beforeunload", saveSnapshot);

        return () => {
            saveSnapshot();
            window.removeEventListener("beforeunload", saveSnapshot);
        };
    }, [saveSnapshot]);

    return (
        <TableContainer>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        actions: [
                            <Fragment key="new">
                                {hasRole(AuthPolicy.Admin) && <Button size="small" startIcon={<Add />} onClick={() => navigate("/employees/create")}>{t("New")}</Button>}
                            </Fragment>,
                        ]
                    } as GridToolbarProps
                }}
                onRowDoubleClick={handleOnRowDoubleClick}
                loading={isFetching}
                ignoreDiacritics
                apiRef={apiRef}
                initialState={gridState}
            />
        </TableContainer>
    );
};