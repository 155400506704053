import { Autocomplete, Button, Menu, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import { hasRole, newGuid, Platform, useLayout } from "wcz-layout";
import { z } from "zod";
import Employee from "../../models/Employee";
import { EmployeeStatus } from "../../models/enums/EmployeeStatus";
import { MaterialState } from "../../models/enums/MaterialState";
import { TransferType } from "../../models/enums/TransferType";
import Material from "../../models/Material";
import MaterialType from "../../models/MaterialType";
import { useCreateCart, useCreateCartItem, useGetCarts } from "../../queries/CartQueries";
import { useGetEmployees } from "../../queries/EmployeeQueries";
import AuthPolicy from "../../utils/AuthPolicy";

interface MaterialHandoverButtonForm {
    employee: Employee | null;
    type: TransferType;
}

interface MaterialHandoverButtonProps {
    material: Material;
    type: MaterialType | undefined;
}

export const MaterialHandoverButton: React.FC<MaterialHandoverButtonProps> = ({ material, type }) => {
    const { t, snackbar } = useLayout();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const employeeAutocompleteRef = useRef<HTMLInputElement>(null);

    const { data: employees } = useGetEmployees({ enabled: open }, { status: EmployeeStatus.Active });
    const { data: carts } = useGetCarts();

    const { Field, Subscribe, handleSubmit, reset } = useForm({
        defaultValues: {
            employee: null,
            type: TransferType.Delivery
        } as MaterialHandoverButtonForm,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => handleMutation(value),
    });

    const { mutate: createCart } = useCreateCart({ preventLocationDelete: !type?.requireSignature }, {
        onSuccess: () => {
            handleClose();
            snackbar({ title: t("MaterialAddedToCart", { name: material.name }), severity: "success" });
        }
    });

    const { mutate: createCartItem } = useCreateCartItem({ preventLocationDelete: !type?.requireSignature }, {
        onSuccess: () => {
            handleClose();
            snackbar({ title: t("MaterialAddedToCart", { name: material.name }), severity: "success" });
        }
    });

    const handleMutation = (value: MaterialHandoverButtonForm) => {
        const cart = carts.find(c => c.type === value.type && c.employee?.id === value.employee?.id);
        if (cart) {
            createCartItem({ cartId: cart.id, material: material, id: newGuid() });
        } else {
            createCart({ id: newGuid(), employee: value.employee, type: value.type, items: [{ material: material, id: newGuid() }] });
        }
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if ((material.state === MaterialState.Reserved && window.confirm(t("MaterialReservedWarning", { remark: material.remark }))) ||
            material.state !== MaterialState.Reserved)
            handleSubmit();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => { setAnchorEl(null); reset(); };

    useEffect(() => {
        if (open && Platform.isWindows)
            setTimeout(() => employeeAutocompleteRef.current?.focus(), 100);
    }, [open]);

    if (type?.requireHandover && (!!material.stockQuantity && (material.state === MaterialState.New || material.state === MaterialState.Usable || material.state === MaterialState.Reserved)) && hasRole(AuthPolicy.Admin))
        return (
            <Fragment>
                <Button onClick={handleClick}>{t("Handover")}</Button>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    slotProps={{ paper: { sx: { overflow: "visible", mt: 1.5, }, }, }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <Typography variant="h6" sx={{ px: 2 }}>{t("AddToCart")}</Typography>
                    <form onSubmit={handleOnSubmit}>
                        <Stack spacing={2} sx={{ p: 2, width: { xs: 300, lg: 350 } }}>
                            <Field name="employee" validators={{ onChange: z.object({}).required() }}>
                                {({ name, state, handleChange, handleBlur }) =>
                                    <Autocomplete
                                        value={state.value}
                                        options={employees}
                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.id})`}
                                        autoHighlight
                                        onChange={(_, value) => handleChange(value)}
                                        onKeyDown={(event) => event.stopPropagation()}
                                        renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Employee")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required inputRef={employeeAutocompleteRef} />}
                                    />
                                }
                            </Field>
                            <Field name="type" validators={{ onChange: z.string().min(1) }}>
                                {({ name, state, handleChange, handleBlur }) =>
                                    <Autocomplete
                                        value={state.value}
                                        options={Object.values(TransferType).filter(type => type !== TransferType.Receive)}
                                        autoHighlight
                                        onChange={(_, value) => handleChange(value!)}
                                        onKeyDown={(event) => event.stopPropagation()}
                                        renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Type")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />}
                                    />
                                }
                            </Field>
                            <Subscribe selector={(state) => [state.canSubmit]}>
                                {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                            </Subscribe>
                        </Stack>
                    </form>
                </Menu>
            </Fragment>
        );

    return null;
};