export enum MaterialState {
    New = "New",
    Usable = "Usable",
    NotUsable = "NotUsable",
    UnderRepair = "UnderRepair",
    Sold = "Sold",
    Scrap = "Scrap",
    Lost = "Lost",
    Reserved = "Reserved",
    InUse = "InUse",
    ReadyToHandover = "ReadyToHandover"
}