import { Delete } from "@mui/icons-material";
import { Autocomplete, Card, CardActions, CardContent, CardHeader, Grid2, IconButton, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useRef } from "react";
import { useLayout } from "wcz-layout";
import Cart from "../../models/Cart";
import CartItem from "../../models/CartItem";
import Location from "../../models/Location";
import MaterialOptions from "../../models/MaterialOptions";
import { MaterialIcon } from "../../pages/materials/MaterialIcon";
import { useDeleteCart, useDeleteCartItem } from "../../queries/CartQueries";
import { useUpdateMaterial } from "../../queries/MaterialQueries";
import { TransferType } from "../../models/enums/TransferType";

interface CartItemCardProps {
    item: CartItem;
    cart: Cart;
    tab: number;
    setTab: (tab: number) => void;
    options: MaterialOptions;
}

export const CartItemCard: React.FC<CartItemCardProps> = ({ item, cart, tab, setTab, options }) => {
    const { t } = useLayout();

    const type = options.types.find(t => t.name === item.material.type);

    const { mutate: deleteCart } = useDeleteCart();

    const { mutate: deleteCartItem } = useDeleteCartItem({
        onSuccess: () => {
            if (!cart.items.length) {
                deleteCart(cart.id);
                setTab(0);
            };
        }
    });

    const handleDeleteCartItem = () => deleteCartItem(item.id);

    const { mutate } = useUpdateMaterial();

    const updateLocation = (location: Location | null) => mutate({ ...item.material, location });

    const hostnameTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const updateHostname = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (hostnameTimeoutRef.current)
            clearTimeout(hostnameTimeoutRef.current);

        hostnameTimeoutRef.current = setTimeout(() => {
            mutate({ ...item.material, hostname: e.target.value });
        }, 1000);
    };

    return (
        <Card variant="outlined" key={item.id}>
            <CardHeader title={item.material.name} subheader={item.material.fixAsset ?? item.material.imei} avatar={<MaterialIcon material={item.material} />} action={<IconButton onClick={handleDeleteCartItem}><Delete /></IconButton>} />

            {cart.type === TransferType.Delivery &&
                <CardContent sx={{ pt: 0 }}>
                    <Grid2 container spacing={2}>
                        {type?.requireLocation &&
                            <Grid2 size={{ xs: 12 }}>
                                <Autocomplete
                                    value={item.material.location}
                                    options={options.locations}
                                    getOptionLabel={option => option.remark ? `${option.name} (${option.remark})` : option.name}
                                    autoHighlight
                                    onChange={(_, value) => updateLocation(value)}
                                    renderInput={(params) => <TextField {...params} fullWidth size="small" label={t("Location")} />}
                                />
                            </Grid2>
                        }
                        {type?.hasHostname &&
                            <Grid2 size={{ xs: 12 }}>
                                <TextField fullWidth size="small" label={t("Hostname")} defaultValue={item.material.hostname} onChange={updateHostname} />
                            </Grid2>
                        }
                    </Grid2>
                </CardContent>
            }

            <CardActions>
                <Typography variant="caption" color="text.secondary">{item.createdBy} {moment(item.created).fromNow()}</Typography>
            </CardActions>
        </Card>
    );
};