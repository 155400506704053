import { DataGridPremium, GridColDef, GridInitialState, useGridApiRef } from "@mui/x-data-grid-premium";
import Trail from "../../models/Trail";
import { TrailValueList } from "./TrailValueList";
import { GridToolbar, useLayout, useLocalStorageState } from "wcz-layout";
import { useLayoutEffect } from "react";

interface TrailDataGridProps {
    data: Trail[];
    isFetching: boolean;
}

export const TrailDataGrid: React.FC<TrailDataGridProps> = ({ data, isFetching }) => {
    const { t } = useLayout();
    const [gridState, setGridState] = useLocalStorageState<GridInitialState>("trailsGridState");
    const apiRef = useGridApiRef();

    const columns: GridColDef<Trail>[] = [
        { field: "dateTime", headerName: t("Date"), width: 200, type: "dateTime", valueGetter: value => value && new Date(value) },
        { field: "userName", headerName: t("UpdatedBy"), width: 150, },
        { field: "userId", headerName: "ID", width: 150, },
        { field: "type", headerName: t("Type"), width: 150, },
        {
            field: "oldValues", headerName: t("OldValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
        {
            field: "newValues", headerName: t("NewValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
    ];

    const saveSnapshot = () => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            setGridState(currentState);
        }
    };

    useLayoutEffect(() => {
        window.addEventListener("beforeunload", saveSnapshot);

        return () => {
            saveSnapshot();
            window.removeEventListener("beforeunload", saveSnapshot);
        };
    }, [saveSnapshot]);

    return (
        <DataGridPremium
            rows={data}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            loading={isFetching}
            ignoreDiacritics
            getRowHeight={() => "auto"}
            apiRef={apiRef}
            initialState={gridState}
        />
    );
};