import { Box, Tabs } from "@mui/material";
import { FC, ReactNode } from "react";

interface VerticalTabsProps {
    tabs: ReactNode[];
    value: number;
    onChange: (value: number) => void;
    children?: ReactNode;
}

export const VerticalTabs: FC<VerticalTabsProps> = ({ tabs, value, onChange, children }) => {

    return (
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex", height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" } }}>
            <Tabs orientation="vertical" variant="scrollable" value={value} onChange={(e, value) => onChange(value)} sx={{ borderRight: 1, borderColor: "divider" }} >
                {tabs}
            </Tabs>

            {children}
        </Box>
    );
};