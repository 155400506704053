import { ProductionQuantityLimits } from "@mui/icons-material";
import { Chip, DialogContent, Stack } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { LayoutContext, LayoutDialog } from "wcz-layout";
import PaginationFilter from "../../../../models/base/PaginationFilter";
import { useGetMaterialOptions, useGetMaterialSearch } from "../../../../queries/MaterialQueries";
import { SearchMaterialCard } from "../SearchMaterialCard";
import { useGetCarts } from "../../../../queries/CartQueries";

const initPaginationFilter: PaginationFilter = {
    advancedFilter: {
        logic: "and",
        filters: [
            {
                field: "safetyStock",
                operator: "neq",
                value: null
            }
        ]
    }
};

export default function MaterialWithoutQuantity() {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useContext(LayoutContext);

    const { data } = useGetMaterialSearch(initPaginationFilter, { select: response => ({ ...response, data: response.data.filter(material => material.stockQuantity! < material.safetyStock!) }) });
    const { data: materialOptions } = useGetMaterialOptions({ enabled: open });
    const { data: carts } = useGetCarts({ enabled: open });

    if (!data.data.length) return null;

    return (
        <Fragment>
            <Chip
                label={`${t("LowStockQuantity")} (${data.data.length})`}
                icon={<ProductionQuantityLimits />}
                onClick={() => setOpen(true)}
                color={data.data.length ? "warning" : "default"}
            />

            <LayoutDialog open={open} onClose={() => setOpen(false)} title={t("LowStockQuantity")} color="warning" maxWidth="md">
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        {data.data.map(employee => <SearchMaterialCard key={employee.id} material={employee} options={materialOptions} carts={carts} />)}
                    </Stack>
                </DialogContent>
            </LayoutDialog>
        </Fragment>
    );
}