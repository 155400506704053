import { Save, VerifiedUser } from "@mui/icons-material";
import { Box, Card, CardActionArea, CardActions, CardContent, CardHeader, Chip, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLayout } from "wcz-layout";
import Material from "../../../models/Material";
import MaterialOptions from "../../../models/MaterialOptions";
import { MaterialIcon } from "../../../pages/materials/MaterialIcon";
import { useUpdateMaterial } from "../../../queries/MaterialQueries";
import { MaterialHandoverButton } from "../../material/MaterialHandoverButton";
import { MaterialReceiveButton } from "../../material/MaterialReceiveButton";
import { MaterialRegistrationButton } from "../../material/MaterialRegistrationButton";
import { MaterialState } from "../../../models/enums/MaterialState";
import Cart from "../../../models/Cart";

interface SearchMaterialCardProps {
    material: Material;
    options: MaterialOptions;
    carts: Cart[];
}

export const SearchMaterialCard: React.FC<SearchMaterialCardProps> = ({ material, options, carts }) => {
    const { t, snackbar } = useLayout();
    const navigate = useNavigate();
    const [deductQuantity, setDeductQuantity] = useState(1);

    const type = options.types.find(t => t.name === material.type);

    const canBeDeducted = !type?.requireHandover && (material.stockQuantity !== null && material.stockQuantity > 0 && deductQuantity <= material.stockQuantity);

    const { mutate } = useUpdateMaterial({
        onSuccess: () => snackbar({ title: t("QuantityDeducted", { quantity: deductQuantity }), severity: "info" })
    });

    const handleDeductQuantity = () => mutate({ ...material, stockQuantity: material.stockQuantity! - deductQuantity });

    const getStateColor: string = useMemo(() => {
        switch (material.state) {
            case MaterialState.ReadyToHandover: return "info.main";
            case MaterialState.Reserved: return "primary.main";
            case MaterialState.Sold: return "warning.main";
            case MaterialState.UnderRepair: return "warning.main";
            case MaterialState.NotUsable: return "error.main";
            case MaterialState.Lost: return "error.main";
            case MaterialState.Scrap: return "error.main";
            default: return "text.primary";
        }
    }, [material.state]);

    const getRemarkColor: string = useMemo(() => {
        switch (material.state) {
            case MaterialState.Reserved: return "primary.main";
            default: return "text.primary";
        }
    }, [material.state]);

    const readyToHandover = useMemo(() => carts.find(c => c.items?.find(i => i.material.id === material.id))?.employee, [material, carts]);

    return (
        <Card variant="outlined">
            <CardActionArea onClick={() => navigate(`/materials/${material.id}`)} sx={theme => ({ bgcolor: grey[theme.palette.mode === "dark" ? 900 : 100] })}>
                <CardHeader title={material.name} subheader={material.type} avatar={<MaterialIcon material={material} />} action={(type?.hasDeviceId && <Chip size="small" label={t(material.registration ? "Registered" : "Unregistered")} color={material.registration ? "success" : "default"} icon={material.registration ? <VerifiedUser fontSize="small" /> : undefined} />)} />
            </CardActionArea>
            <CardContent>
                {type?.hasSerialNumber && material.serialNumber && <Typography variant="body2"><b>SN:</b> {material.serialNumber}</Typography>}
                {type?.hasFixAsset && material.fixAsset && <Typography variant="body2"><b>FixAsset:</b> {material.fixAsset}</Typography>}
                {type?.hasImei && material.imei && <Typography variant="body2"><b>IMEI:</b> {material.imei}</Typography>}
                {type?.hasManufacturer && material.manufacturer && <Typography variant="body2"><b>{t("Manufacturer")}:</b> {material.manufacturer}</Typography>}
                {type?.hasModel && material.model && <Typography variant="body2"><b>{t("Model")}:</b> {material.model}</Typography>}
                {type?.hasState && material.state && <Typography variant="body2" color={getStateColor}><b>{t("State")}:</b> {t(material.state)}</Typography>}
                {readyToHandover && <Typography variant="subtitle2" color={getStateColor}><b>{t("ReservedBy")}: </b>{`${readyToHandover.firstName} ${readyToHandover.lastName} (${readyToHandover.id})`}</Typography>}
                {type?.hasLocation && material.location && <Typography variant="body2"><b>{t("Location")}:</b> <Box sx={{ display: "inline", "&:hover": { textDecoration: "underline", cursor: "pointer" } }} onClick={() => navigate(`/locations/${material.location?.id}`)}>{material.location.name}</Box></Typography>}
                {material.keeper && <Typography variant="body2"><b>{t("Keeper")}:</b> <Box sx={{ display: "inline", "&:hover": { textDecoration: "underline", cursor: "pointer" } }} onClick={() => navigate(`/employees/${material.keeper?.id}`)}>{`${material.keeper.firstName} ${material.keeper.lastName} (${material.keeper.id})`}</Box></Typography>}
                {type?.hasDepartment && material.department && <Typography variant="body2"><b>{t("Department")}:</b> {material.department}</Typography>}
                {type?.hasPartNumber && material.partNumber && <Typography variant="body2"><b>{t("PartNumber")}:</b> {material.partNumber}</Typography>}
                {type?.hasCompany && material.company && <Typography variant="body2"><b>{t("Company")}:</b> {material.company}</Typography>}
                {type?.hasOrderDate && material.orderDate && <Typography variant="body2"><b>{t("OrderDate")}:</b> {material.orderDate}</Typography>}
                {type?.hasMonthsOfWarranty && !!material.monthsOfWarranty && <Typography variant="body2"><b>{t("Warranty")}:</b> {material.monthsOfWarranty} {t("Months")}</Typography>}
                {type?.hasUnderRepairTo && material.underRepairTo && <Typography variant="body2"><b>{t("UnderRepairTo")}:</b> {material.underRepairTo}</Typography>}
                {type?.hasItId && material.itId && <Typography variant="body2"><b>ITID:</b> {material.itId}</Typography>}
                {type?.hasStockQuantity && !!material.stockQuantity && <Typography variant="body2"><b>{t("StockQuantity")}:</b> {material.stockQuantity}</Typography>}
                {type?.hasSafetyStock && !!material.safetyStock && <Typography variant="body2"><b>{t("SafetyStock")}:</b> {material.safetyStock}</Typography>}
                {type?.hasPrice && !!material.price && <Typography variant="body2"><b>{t("Price")}:</b> {material.price}</Typography>}
                {type?.hasCurrency && material.currency && <Typography variant="body2"><b>{t("Currency")}:</b> {material.currency}</Typography>}
                {type?.hasHostname && material.hostname && <Typography variant="body2"><b>{t("Hostname")}:</b> {material.hostname}</Typography>}
                {type?.hasRemark && material.remark && <Typography variant="body2" color={getRemarkColor}><b>{t("Remark")}:</b> {material.remark}</Typography>}
                {type?.hasIp && material.ip && <Typography variant="body2"><b>IP:</b> {material.ip}</Typography>}
                {type?.hasMacLan && material.macLan && <Typography variant="body2"><b>MAC LAN:</b> {material.macLan}</Typography>}
                {type?.hasMacWlan && material.macWlan && <Typography variant="body2"><b>MAC WLAN:</b> {material.macWlan}</Typography>}
            </CardContent>

            <CardActions sx={{ justifyContent: "end" }}>
                <MaterialRegistrationButton material={material} type={type} />
                <MaterialHandoverButton material={material} type={type} />
                <MaterialReceiveButton material={material} type={type} />
                {canBeDeducted && <TextField type="number" size="small" value={deductQuantity} onChange={e => setDeductQuantity(parseInt(e.target.value))} sx={{ maxWidth: 150 }}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton size="small" color="primary" onClick={handleDeductQuantity}>
                                        <Save />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                />}
            </CardActions>
        </Card>
    );
};