import { QrCode } from "@mui/icons-material";
import { Autocomplete, Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid2, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { useForm } from "@tanstack/react-form";
import { ZodValidator, zodValidator } from "@tanstack/zod-form-adapter";
import moment from "moment";
import { FormEvent, Fragment, useContext, useState } from "react";
import save from "save-file";
import { fetchFileGet, LayoutContext, newGuid, Platform, TableContainer } from "wcz-layout";
import { z } from "zod";
import { DynamsoftScanner } from "../../components/common/DynamsoftScanner";
import { UploadButton } from "../../components/common/UploadButton";
import { MaterialDataGrid } from "../../components/material/MaterialDataGrid";
import { MaterialState } from "../../models/enums/MaterialState";
import Material, { initMaterial } from "../../models/Material";
import { useCreateMaterial, useGetMaterialOptions, useImportMaterial } from "../../queries/MaterialQueries";
import { baseUrl } from "../../utils/BaseUrl";

export const CreateMaterialPage: React.FC = () => {
    const { t, snackbar } = useContext(LayoutContext);
    const [scanner, setScanner] = useState<"location" | "serialNumber" | "fixAsset" | "imei" | "partNumber" | "macLan" | "macWlan" | null>(null);
    const [materialHistory, setMaterialHistory] = useState<Material[]>([]);

    const { data: options } = useGetMaterialOptions();

    const { Field, Subscribe, handleSubmit, useStore, reset, setFieldValue } = useForm<Material, ZodValidator>({
        defaultValues: initMaterial,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => mutate({
            ...value,
            id: newGuid(),
        }),
    });

    const store = useStore(state => state.values);

    const type = options.types.find(t => t.name === store.type);

    const { mutate } = useCreateMaterial({
        onSuccess: (data) => {
            snackbar({ title: `${data.name} ${t("Created").toLowerCase()}` });
            reset();
            setMaterialHistory([...materialHistory, data]);
        },
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    const handleOnFixAssetChange = (value: string | null) => {
        if (value?.length === 5 && value.endsWith("9")) {
            const companyCode: string = value.substring(0, 4).toUpperCase();
            if (options.highestFixAsset)
                setFieldValue("fixAsset", `${companyCode}${options.highestFixAsset + 1}`);
        }
    };

    const handleOnItIdFocus = (value: string | null) => () => {
        if (options.highestItId && !value) {
            const increasedItId = options.highestItId + 1;
            const paddedItId = increasedItId.toString().padStart(5, "0");
            setFieldValue("itId", `IT${paddedItId}`);
        }
    };

    const handleOnScan = (value: string) => {
        if (scanner === "location") {
            const scannedLocation = options.locations.find(l => l.name === value);
            if (scannedLocation) setFieldValue("location", scannedLocation);
        } else if (scanner) {
            setFieldValue(scanner, value);
        }
        setScanner(null);
    };

    const handleDownloadTemplate = () => {
        fetchFileGet(`${baseUrl}/v1/material/exportTemplate`, undefined)
            .then(data => save(data, "material-import"));
    };

    const { mutate: importMaterial, isPending: isImporting } = useImportMaterial({
        onSuccess: data => setMaterialHistory([...materialHistory, ...data]),
    });

    const handleImportData = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) return;

        let formData: FormData = new FormData();
        formData.append("file", e.target.files[0]);
        importMaterial(formData);
    };

    return (
        <Container sx={{ my: 2 }}>
            <Stack spacing={3}>
                <Card variant="outlined">
                    <CardHeader title={t("CreateMaterial")} action={
                        <Fragment>
                            <Button onClick={handleDownloadTemplate}>{t("Template")}</Button>
                            <UploadButton onChange={handleImportData}>{t("Import")}</UploadButton>
                        </Fragment>
                    } />
                    <form onSubmit={handleOnSubmit}>
                        <CardContent>
                            <Grid2 container spacing={2}>
                                <Grid2 size={12}>
                                    <Field name="type" validators={{ onChange: z.string().min(1).max(50) }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <Autocomplete
                                                value={state.value}
                                                options={options.types.map(t => t.name)}
                                                autoHighlight
                                                onChange={(_, value) => handleChange(value!)}
                                                renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Type")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasSerialNumber ? undefined : "none"}>
                                    <Field name="serialNumber" validators={{ onChange: z.string().max(50).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label="SN" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} slotProps={{
                                                    input: {
                                                        startAdornment: (Platform.isAndroid || Platform.isIOS) && (
                                                            <InputAdornment position="start" onClick={() => setScanner("serialNumber")}>
                                                                <QrCode />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                }}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasFixAsset ? undefined : "none"}>
                                    <Field name="fixAsset" validators={{
                                        onChange: ({ value, fieldApi }) => {
                                            if (fieldApi.form.getFieldValue("fixAsset")?.length) {
                                                handleOnFixAssetChange(value);
                                                const { success, error } = z.string().length(12).safeParse(value);
                                                if (!success) return error.issues[0].message;
                                            }
                                        },
                                        onChangeListenTo: ["fixAsset"]
                                    }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value ? e.target.value : null)} onBlur={handleBlur} fullWidth size="small"
                                                label="FixAsset" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} slotProps={{
                                                    input: {
                                                        startAdornment: (Platform.isAndroid || Platform.isIOS) && (
                                                            <InputAdornment position="start" onClick={() => setScanner("fixAsset")}>
                                                                <QrCode />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                }} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasImei ? undefined : "none"}>
                                    <Field name="imei" validators={{ onChange: z.string().max(50).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label="IMEI" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} slotProps={{
                                                    input: {
                                                        startAdornment: (Platform.isAndroid || Platform.isIOS) && (
                                                            <InputAdornment position="start" onClick={() => setScanner("imei")}>
                                                                <QrCode />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                }}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasManufacturer ? undefined : "none"}>
                                    <Field name="manufacturer" validators={{ onChange: z.string().max(50).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <Autocomplete
                                                value={state.value ?? ""}
                                                options={options.manufacturers}
                                                autoHighlight
                                                onInputChange={(_, value) => handleChange(value)}
                                                freeSolo
                                                renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Manufacturer")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasModel ? undefined : "none"}>
                                    <Field name="model" validators={{ onChange: z.string().max(100).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <Autocomplete
                                                value={state.value ?? ""}
                                                options={options.models}
                                                autoHighlight
                                                onInputChange={(_, value) => handleChange(value)}
                                                freeSolo
                                                renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Model")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasState ? undefined : "none"}>
                                    <Field name="state" validators={{ onChange: z.string().max(50).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <Autocomplete
                                                value={state.value}
                                                options={Object.values(MaterialState)}
                                                autoHighlight
                                                onChange={(_, value) => handleChange(value!)}
                                                renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("State")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasLocation ? undefined : "none"}>
                                    <Field name="location">
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <Autocomplete
                                                value={state.value}
                                                options={options.locations}
                                                getOptionLabel={option => option.name}
                                                autoHighlight
                                                onChange={(_, value) => handleChange(value)}
                                                renderOption={(props, option) => {
                                                    const { key, ...optionProps } = props;
                                                    return (
                                                        <Box key={key} component="li" {...optionProps}>
                                                            <Box>
                                                                <Typography>{option.name}</Typography>
                                                                {(option.isWarehouse && option.remark) && <Typography variant="caption" display="block">{option.remark}</Typography>}
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                                renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Location")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]}
                                                    slotProps={{
                                                        input: {
                                                            ...params.InputProps,
                                                            startAdornment: (Platform.isAndroid || Platform.isIOS) && (
                                                                <InputAdornment position="end" onClick={() => setScanner("location")}>
                                                                    <QrCode />
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    }}
                                                />
                                                }
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasDepartment ? undefined : "none"}>
                                    <Field name="department" validators={{ onChange: z.string().max(50).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label={t("Department")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasPartNumber ? undefined : "none"}>
                                    <Field name="partNumber" validators={{ onChange: z.string().max(50).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label="PN" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} slotProps={{
                                                    input: {
                                                        startAdornment: (Platform.isAndroid || Platform.isIOS) && (
                                                            <InputAdornment position="start" onClick={() => setScanner("partNumber")}>
                                                                <QrCode />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                }}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasCompany ? undefined : "none"}>
                                    <Field name="company" validators={{ onChange: z.string().max(20).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <Autocomplete
                                                value={state.value ?? ""}
                                                options={options.companies}
                                                autoHighlight
                                                onInputChange={(_, value) => handleChange(value)}
                                                freeSolo
                                                renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Company")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasOrderDate ? undefined : "none"}>
                                    <Field name="orderDate">
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <DatePicker value={state.value ? moment(state.value) : null} onChange={newValue => handleChange(moment(newValue).format())} label={t("EffectiveTo")}
                                                slotProps={{ textField: { name: name, size: "small", fullWidth: true, onBlur: handleBlur, error: !!state.meta.errors.length, helperText: state.meta.errors[0] } }} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasMonthsOfWarranty ? undefined : "none"}>
                                    <Field name="monthsOfWarranty" validators={{ onChange: z.number().nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value ? Number(e.target.value) : null)} onBlur={handleBlur} fullWidth size="small" type="number"
                                                label={t("Warranty")} error={!!state.meta.errors?.length} helperText={state.meta.errors?.[0]} slotProps={{ input: { endAdornment: t("Months") } }} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasUnderRepairTo ? undefined : "none"}>
                                    <Field name="underRepairTo">
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <DatePicker value={state.value ? moment(state.value) : null} onChange={newValue => handleChange(moment(newValue).format())} label={t("UnderRepairTo")}
                                                slotProps={{ textField: { name: name, size: "small", fullWidth: true, onBlur: handleBlur, error: !!state.meta.errors.length, helperText: state.meta.errors[0] } }} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasItId ? undefined : "none"}>
                                    <Field name="itId" validators={{
                                        onChange: ({ value, fieldApi }) => {
                                            if (fieldApi.form.getFieldValue("itId")?.length) {
                                                const { success, error } = z.string().length(7).safeParse(value);
                                                if (!success) return error.issues[0].message;
                                            }
                                        }
                                    }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label="IT ID" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} onFocus={handleOnItIdFocus(state.value)} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasStockQuantity ? undefined : "none"}>
                                    <Field name="stockQuantity" validators={{ onChange: z.number().nonnegative().nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value ? Number(e.target.value) : null)} onBlur={handleBlur} fullWidth size="small" type="number"
                                                label={t("StockQuantity")} error={!!state.meta.errors?.length} helperText={state.meta.errors?.[0]} slotProps={{ input: { endAdornment: t("Pcs").toLocaleLowerCase() } }} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasSafetyStock ? undefined : "none"}>
                                    <Field name="safetyStock" validators={{ onChange: z.number().positive().nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value ? Number(e.target.value) : null)} onBlur={handleBlur} fullWidth size="small" type="number"
                                                label={t("SafetyStock")} error={!!state.meta.errors?.length} helperText={state.meta.errors?.[0]} slotProps={{ input: { endAdornment: t("Pcs").toLocaleLowerCase() } }} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasPrice ? undefined : "none"}>
                                    <Field name="price" validators={{ onChange: z.number().nonnegative().nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value ? Number(e.target.value) : null)} onBlur={handleBlur} fullWidth size="small" type="number"
                                                label={t("Price")} error={!!state.meta.errors?.length} helperText={state.meta.errors?.[0]} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasCurrency ? undefined : "none"}>
                                    <Field name="currency" validators={{ onChange: z.string().max(10).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <Autocomplete
                                                value={state.value ?? ""}
                                                options={options.currencies}
                                                autoHighlight
                                                onInputChange={(_, value) => handleChange(value)}
                                                freeSolo
                                                renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Currency")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasHostname ? undefined : "none"}>
                                    <Field name="hostname" validators={{ onChange: z.string().max(255).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label={t("Hostname")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasRemark ? undefined : "none"}>
                                    <Field name="remark" validators={{ onChange: z.string().max(255).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label={t("Remark")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasIp ? undefined : "none"}>
                                    <Field name="ip" validators={{ onChange: z.string().max(20).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label="IP" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasMacLan ? undefined : "none"}>
                                    <Field name="macLan" validators={{ onChange: z.string().max(100).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label="MAC LAN" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} slotProps={{
                                                    input: {
                                                        startAdornment: (Platform.isAndroid || Platform.isIOS) && (
                                                            <InputAdornment position="start" onClick={() => setScanner("macLan")}>
                                                                <QrCode />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                }}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                                <Grid2 size={12} display={type?.hasMacWlan ? undefined : "none"}>
                                    <Field name="macWlan" validators={{ onChange: z.string().max(100).nullable() }}>
                                        {({ name, state, handleChange, handleBlur }) =>
                                            <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                label="MAC WLAN" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} slotProps={{
                                                    input: {
                                                        startAdornment: (Platform.isAndroid || Platform.isIOS) && (
                                                            <InputAdornment position="start" onClick={() => setScanner("macWlan")}>
                                                                <QrCode />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                }}
                                            />
                                        }
                                    </Field>
                                </Grid2>
                            </Grid2>
                        </CardContent>

                        <CardActions sx={{ justifyContent: "end" }}>
                            <Subscribe selector={(state) => [state.canSubmit]}>
                                {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                            </Subscribe>
                        </CardActions>
                    </form>
                </Card>

                <Box>
                    <Typography variant="h6">{t("CreateHistory")}</Typography>
                    <TableContainer sx={{ height: 400 }}>
                        <MaterialDataGrid data={materialHistory} isFetching={isImporting} />
                    </TableContainer>
                </Box>
            </Stack>

            <DynamsoftScanner open={!!scanner} setOpen={() => setScanner(null)} onScan={handleOnScan} />
        </Container>
    );
};